var contaminatedIpData;
var vulnerableIpData;

$(document).on('turbolinks:load', function() {
  drawValue();

  google.charts.load('current', {'packages':['corechart']});
  google.charts.setOnLoadCallback(drawChart);

  $menuVunlberableIp = $('.top-section__inner__statistics__chart__menu__vulnerable-ip');
  $menuContaminatedIp = $('.top-section__inner__statistics__chart__menu__contaminated-ip');
  $menuVunlberableIp.on('click', function() {
    if (!$(this).hasClass('active')) {
      $(this).toggleClass('active');
    }
    $menuContaminatedIp.removeClass('active');
    $('.top-section__inner__statistics__chart__draw--vulnerable-ip').show();
    $('.top-section__inner__statistics__chart__draw--contaminated-ip').hide();
    drawChart();
  });
  $menuContaminatedIp.on('click', function() {
    if (!$(this).hasClass('active')) {
      $(this).toggleClass('active');
    }
    $menuVunlberableIp.removeClass('active');
    $('.top-section__inner__statistics__chart__draw--vulnerable-ip').hide();
    $('.top-section__inner__statistics__chart__draw--contaminated-ip').show();
    drawChart();
  });
});

$(window).resize(function() {
  drawChart();
})

function drawValue() {
  var xmlHttpRequest = new XMLHttpRequest();
  xmlHttpRequest.onreadystatechange = function() {
    if (this.readyState == 4 && this.status == 200 ) {
      if (this.responseText) {
        data = JSON.parse(this.responseText);
        $('.top-section__inner__statistics__value__sub-section__inner__value--vulnerable-ip').html(data['vulnerable_ips'] + '<span>個</span>');
        $('.top-section__inner__statistics__value__sub-section__inner__value--contaminated-ip').html(data['contaminated_ips'] + '<span>個</span>');
      }
    }
  }
  xmlHttpRequest.open('GET', 'https://ynu-amii-data.s3-ap-northeast-1.amazonaws.com/ips/value/current.json', true);
  xmlHttpRequest.send(null);
}

function drawChart() {
  contaminatedIpData ? drawContaminatedIpChart(contaminatedIpData) : getAndDrawContaminatedIpChart();
  vulnerableIpData   ? drawVulnerableIpChart(vulnerableIpData)     : getAndDrawVulnerableIpChart();
}

function getAndDrawContaminatedIpChart() {
  var xmlHttpRequest = new XMLHttpRequest();
  xmlHttpRequest.onreadystatechange = function() {
    if (this.readyState == 4 && this.status == 200 ) {
      if (this.responseText) {
        contaminatedIpData = JSON.parse(this.responseText);
        drawContaminatedIpChart(contaminatedIpData)
      }
    }
  }
  xmlHttpRequest.open('GET', 'https://ynu-amii-data.s3-ap-northeast-1.amazonaws.com/ips/chart/contaminated.json', true);
  xmlHttpRequest.send(null);
}

function drawContaminatedIpChart(rawData) {
  if (!document.getElementsByClassName('top-section__inner__statistics__chart__draw--contaminated-ip')[0]) {
    return;
  }
  var data = google.visualization.arrayToDataTable(rawData)
  var options = {
    legend:{position:'none'},
    vAxis: {minValue: 0, textStyle: {color: '#677881'}, gridlines: {count: 0}, baselineColor: '#ccd2d5'},
    hAxis: {textStyle: {color: '#677881'}, showTextEvery: 12, baselineColor: '#ccd2d5'},
    colors: ['#dc4f42'],
    backgroundColor: '#f0f2f2',
    chartArea: {top: '5%', bottom: '10%', left: '50', right: '20'}
  };
  var chart = new google.visualization.LineChart(document.getElementsByClassName('top-section__inner__statistics__chart__draw--contaminated-ip')[0]);
  chart.draw(data, options);
}

function getAndDrawVulnerableIpChart() {
  var xmlHttpRequest = new XMLHttpRequest();
  xmlHttpRequest.onreadystatechange = function() {
    if (this.readyState == 4 && this.status == 200 ) {
      if (this.responseText) {
        vulnerableIpData = JSON.parse(this.responseText);;
        drawVulnerableIpChart(vulnerableIpData);
      }
    }
  }
  xmlHttpRequest.open('GET', 'https://ynu-amii-data.s3-ap-northeast-1.amazonaws.com/ips/chart/vulnerable.json', true);
  xmlHttpRequest.send(null);
}

function drawVulnerableIpChart(rawData) {
  if (!document.getElementsByClassName('top-section__inner__statistics__chart__draw--vulnerable-ip')[0]) {
    return;
  }
  var data = google.visualization.arrayToDataTable(rawData)
  var options = {
    legend:{position:'none'},
    vAxis: {minValue: 0, textStyle: {color: '#677881'}, gridlines: {count: 0}, baselineColor: '#ccd2d5'},
    hAxis: {textStyle: {color: '#677881'}, showTextEvery: 12, baselineColor: '#ccd2d5'},
    colors: ['#dc4f42'],
    backgroundColor: '#f0f2f2',
    chartArea: {top: '5%', bottom: '10%', left: '50', right: '20'}
  };
  var chart = new google.visualization.LineChart(document.getElementsByClassName('top-section__inner__statistics__chart__draw--vulnerable-ip')[0]);
  chart.draw(data, options);
}
