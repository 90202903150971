$(document).on('turbolinks:load', function() {
  $('.top-section__inner__qa__content').on('click', function() {
    $(this).find('.top-section__inner__qa__content__button__mark').toggleClass('active')
    $(this).children('.top-section__inner__qa__content__a').slideToggle();
  });

  // #qaN が付いていたら QA セクションの N 番目の項目を active にする
  section = $(location).attr('hash');
  if (section) {
    section = section.replace('#', '');
    console.log(section);
    if (/^qa\d+/.test(section)) {
    console.log(section);
      $('.top-section--' + section).find('.top-section__inner__qa__content__button__mark').toggleClass('active');
      $('.top-section--' + section).children('.top-section__inner__qa__content__a').slideToggle();
    }
  }
});

