$(document).on('turbolinks:load', function() {
  // 画面が十分縦長の場合は矢印を表示しない
  if (arrowExists()) {
    if ($(window).height() >= $('.infection-recheck').offset().top - 150) {
      $('.scroll-arrow').hide();
    }
  }

  setInterval(function() {
    $('.scroll-arrow').animate({bottom: '50px'}, 1000);
    $('.scroll-arrow').animate({bottom: '10px'}, 1000);
  }, 2000);

  if (arrowExists()) {
    $('.scroll-arrow').on('click', function() {
      $('html,body').animate({scrollTop: $(window).scrollTop() + $(window).height()}, 1000);
    });
  }

  $(window).on("scroll", function() {
    if (arrowExists()) {
      if ($(window).scrollTop() >= $('.infection-recheck').offset().top - 150) {
        $('.scroll-arrow').fadeOut(500);
      }
    }
  });
});

function arrowExists() {
  return !!document.getElementsByClassName('scroll-arrow').length;
}

