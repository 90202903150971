$(document).on('turbolinks:load', function() {
  $('.header__button--menu').on('click', function() {
    $('.header__menu').toggle();
    $('.header__button--menu').toggleClass('active')
  });

  $(document).click(function(event) {
    if (!$(event.target).closest('.header__menu').length && !$(event.target).closest('.header__button--menu').length) {
      $('.header__menu').hide();
      $('.header__button--menu').removeClass('active');
    }
  });
});
