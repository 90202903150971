$(document).on('turbolinks:load', function() {
  showFadeInElements();

  $(window).scroll(function () {
    showFadeInElements();
  });
});

function showFadeInElements() {
  $('.fade-in').each(function () {
    var scroll = $(window).scrollTop();
    var offset = $(this).offset().top;
    var windowHeight = $(window).height();
    if (scroll > offset - windowHeight + 150) {
        $(this).addClass("scroll-in");
    }
  });
}
