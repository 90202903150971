$(document).on('turbolinks:load', function() {
  $('#new_support_request').on('ajax:success', function(e) {
    $('.result__content__support__error').html('');
    $('.result__content__support__success').html(e.detail[0].messages.join('<br>'));
  });
  $('#new_support_request').on('ajax:error', function(e) {
    $('.result__content__support__success').html('');
    $('.result__content__support__error').html(e.detail[0].messages.join('<br>'));
  });

  $('#new_questionnaire').on('ajax:success', function(e) {
    $('.result__content__questionnaire__error').html('');
    $('.result__content__questionnaire__success').html(e.detail[0].messages.join('<br>'));
  });
  $('#new_questionnaire').on('ajax:error', function(e) {
    $('.result__content__questionnaire__success').html('');
    $('.result__content__questionnaire__error').html(e.detail[0].messages.join('<br>'));
  });
});
