$(document).on('turbolinks:load', function() {
  var sidebarPosition;
  if (sidebarExists()) {
    sidebarPosition = $('.sidebar').offset().top - 150;
  }
  $(window).on("scroll", function() {
    if (sidebarExists()) {
      if ($(window).scrollTop() > sidebarPosition) {
        $('.sidebar').addClass('sticky');
      }
      else {
        $('.sidebar').removeClass('sticky');
      }

      if ($(window).scrollTop() > $('.top-section--news').offset().top - 150) {
        resetSidebar();
        $('.sidebar__news').addClass('active');
      }
      else {
        resetSidebar();
      }
      if ($(window).scrollTop() > $('.top-section--about').offset().top - 150) {
        resetSidebar();
        $('.sidebar__about').addClass('active');
      }
      if ($(window).scrollTop() > $('.top-section--impact').offset().top - 150) {
        resetSidebar();
        $('.sidebar__impact').addClass('active');
      }
      if ($(window).scrollTop() > $('.top-section--statistics').offset().top - 150) {
        resetSidebar();
        $('.sidebar__statistics').addClass('active');
      }
      if ($(window).scrollTop() > $('.top-section--qa').offset().top - 150) {
        resetSidebar();
        $('.sidebar__qa').addClass('active');
      }
      if ($(window).scrollTop() > $('.top-section--operation').offset().top - 150) {
        resetSidebar();
        $('.sidebar__operation').addClass('active');
      }
    }
  })

  $(window).resize(function() {
    if (sidebarExists()) {
      if ($(window).scrollTop() > $('.sidebar').offset().top - 150) {
        $('.sidebar').addClass('sticky');
      }
      else {
        $('.sidebar').removeClass('sticky');
      }
    }
  });

  section = $(location).attr('hash');
  if (section) {
    section = section.replace('#', '');
    scrollToSection(section, 0);
  }

  $('.sidebar__news').on('click', function() {scrollToSection('news', 500)});
  $('.sidebar__about').on('click', function() {scrollToSection('about', 500)});
  $('.sidebar__impact').on('click', function() {scrollToSection('impact', 500)});
  $('.sidebar__statistics').on('click', function() {scrollToSection('statistics', 500)});
  $('.sidebar__qa').on('click', function() {scrollToSection('qa', 500)});
  $('.sidebar__operation').on('click', function() {scrollToSection('operation', 500)});
});

function sidebarExists() {
  return !!document.getElementsByClassName('sidebar').length;
}

function resetSidebar() {
  $('.sidebar__news').removeClass('active');
  $('.sidebar__about').removeClass('active');
  $('.sidebar__impact').removeClass('active');
  $('.sidebar__statistics').removeClass('active');
  $('.sidebar__qa').removeClass('active');
  $('.sidebar__operation').removeClass('active');
}

function scrollToSection(section, duration) {
  $('html,body').animate({scrollTop: $('.top-section--' + section).offset().top - 150 + 1}, duration);
}
